<template>
  <div class=" flexcc login_page">

    <div class="new-login" v-if=" shoplistVisible == 1">
      <el-form :model="loginForm" ref="loginForm" status-icon :rules="rules" :hide-required-asterisk="true" @keyup.enter.native="submitForm()">
        <div class="mrb20 size30" style="width: 80%;">登录中心</div>
        <el-form-item prop="username" style="margin-top: 30px">
          <el-input v-model="loginForm.username" placeholder="账号" clearable prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" placeholder="密码" clearable type="password" prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-checkbox v-model="checked" @change='remberPwd' true-label='true' false-label='false'>记住密码</el-checkbox>
        <div class="mrt20">
          <el-button type="primary" class="form_btn" @click="loginClick()" :loading="loading">登录</el-button>
        </div>
      </el-form>
    </div>
    <!-- <el-button type="primary">
        <div class="szie40">
          登 录
        </div>
      </el-button> -->

    <div class="shoplist_box flex-column" v-if="login_success == 0 && shoplistVisible == 2">
      <div class="flex justify-between pd20 size16">
        <div>请选择对应的账号进行登录</div>
        <div @click="shoplistVisible = 1"> <i class="el-icon-close"></i></div>
      </div>
      <div class="shoplist_item  flex-column flex1 flex">
        <div class="mrt20 flex mrl20  align-center" v-for="(i,index) in shoplist" :key="index">
          <div class="mrr10">
            <el-image style="width: 50px; height: 50px" :src="i.shop_icon" fit="fill"></el-image>
          </div>
          <div class="flex mrr20 align-center justify-between  flex1">
            <div>
              <div class="mrb5">{{ i.name }}</div>
              <div class="align-center flex">
                <div class="mrr10">
                  店铺ID
                </div>
                <div>{{ i.shop_id }}</div>
              </div>
            </div>
            <div>
              <el-button type="text" @click="Login(i)">登录</el-button>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      shoplistVisible: 1,
      login_success: "", //是否显示商店列表
      loading: false,
      checked: localStorage.getItem("remberPwd"),

      loginForm: {
        username: localStorage.getItem("user"),
        password: localStorage.getItem("pwd"),
        shop_id: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      shoplist: [],
    };
  },

  created() {
    console.log(
      ' localStorage.getItem("remberPwd")',
      localStorage.getItem("remberPwd")
    );
  },
  mounted() {},
  computed: {},

  methods: {
    remberPwd(val) {
      console.log("val", val);

      localStorage.setItem("remberPwd", val);
    },
    loginClick() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // Loading 加载
          this.loading = true;
          this.$Api
            .storeLogin(this.loginForm)
            .then((data) => {
              console.log("data", data);
              if (data.code !== 200) {
                // 错误执行
                this.$message({
                  message: data.msg,
                  type: "error",
                });
              }
              switch (data.code) {
                case 200:
                  let { username, password } = this.loginForm;

                  console.log("55555555555555555555555");

                  localStorage.setItem(
                    "user",
                    this.checked == "true" ? username : ""
                  );
                  localStorage.setItem(
                    "pwd",
                    this.checked == "true" ? password : ""
                  );

                  this.login_success = data.data.login_success;

                  if (data.data.login_success == 0) {
                    this.shoplistVisible = 2;
                    console.log(" this.login_success", this.login_success);
                    this.shoplist = data.data.shop_list;
                    // 控制下拉框和进入后台按钮的显示
                    this.isLogin = true;
                    this.$notify({
                      title: "登录成功",
                      message: "请选择店铺进入后台",
                      type: "success",
                    });
                  }

                  if (data.data.login_success == 1) {
                    this.isLogin = true;
                    const token = data.data.token;
                    const shop_name = data.data.shop_name;
                    console.log(token, "token");

                    this.$store.commit("setToken", token);
                    this.$store.commit("shoolName", shop_name);

                    this.$store.commit("industryType", data.data.industry_type);

                    this.$notify({
                      title: "登录成功",
                      message: "",
                      type: "success",
                    });
                  }

                  break;

                case 202:
                  this.loginForm.passwordError = data.msg;

                  break;
              }
            })
            // 成功失败执行
            .finally(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    Login(item) {
      this.loginForm.shop_id = item.shop_id;
      console.log("登录的店铺123", item);
      this.$Api.storeLogin(this.loginForm).then((data) => {
        console.log("data", data);
        if (data.code !== 200) {
          // 错误执行
          this.$message({
            message: data.msg,
            type: "error",
          });
        }
        switch (data.code) {
          case 200:
            this.isLogin = true;
            const token = data.data.token;
            const shop_name = data.data.shop_name;
            console.log(token, "token");

            this.$store.commit("setToken", token);
            this.$store.commit("shoolName", shop_name);

            this.$store.commit("industryType", data.data.industry_type);

            this.$notify({
              title: "登录成功",
              message: "",
              type: "success",
            });
            break;

          case 202:
            this.loginForm.passwordError = data.msg;

            break;
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-button--primary {
  width: 80%;
}
::v-deep .el-image__inner {
  border-radius: 50px;
}
.new-login {
  width: 340px;
  height: 300px;
  padding: 50px 60px;
  background-color: #fff;
  position: relative;
  float: left;
  border-radius: 10px;
}
.form_btn {
  background-color: #34acff;
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 20px;
  margin-top: 26px;
}
.login_page {
  width: 100%;
  height: 100vh;
  background-color: #545c64;
}
.shoplist_box {
  height: 500px;
  width: 450px;
  background-color: #fff;
  .shoplist_item {
    overflow-y: scroll;
    width: 100%;
    border-top: 1px solid #c2cad1;
  }
}
</style>
