<template>
  <!-- 头部 -->
  <div class="size20 flex justify-between align-center">
    <!-- 左边 -->
    <div style="display: flex; align-items: center;" :style="getWidth()">
      <div class="flexible_model">
        <i class="el-icon-s-fold flexible" v-show="$store.state.navOpen" @click="$store.state.navOpen = !$store.state.navOpen"></i>
        <i class="el-icon-s-unfold flexible" v-show="!$store.state.navOpen" @click="$store.state.navOpen = !$store.state.navOpen"></i>
      </div>
      <!-- <el-breadcrumb>
        <el-breadcrumb-item v-for="(data, index) in breadcrumbs" :key="index" :to="data.path">{{data.name}}</el-breadcrumb-item>
      </el-breadcrumb> -->
      <Breadcrumb></Breadcrumb>

    </div>
    <!-- 右边 -->
    <div class="flex">
      <div class="white-space">{{ $store.state.shop_name }}</div>
      <el-popover placement="bottom" title="" width="150" trigger="manual" v-model="visible">
        <div class="flexcc size16 pointer" @click="logOut">退出登录</div>
        <i class="el-icon-caret-bottom" slot="reference" @click="visible = !visible"></i>
      </el-popover>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
export default {
  components: { Breadcrumb },
  props: {},
  data() {
    return {
      visible: false,
      breadcrumbs: [],
    };
  },

  created() {
    // console.log(" $store.state.shop_name", $store.state.shop_name);
  },
  mounted() {},
  computed: {},

  methods: {
    logOut() {
      this.$store.dispatch("logout");
      this.$confirm("确认退出登录?", "提示").then(() => {
        // location.href = "/login";
        this.$router.push({
          path: "/login",
        });
      });
    },
    getWidth() {
      var widthStr;
      if (this.$store.state.isPhone) {
        widthStr = "width:100%";
      } else {
        widthStr = this.$store.state.navOpen
          ? "width:calc(100vw - 200px);"
          : "width:calc(100vw - 64px);";
      }
      return widthStr;
    },
  },
};
</script>
<style lang='scss' scoped>
</style>