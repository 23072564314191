<template>
  <div class="home fullscreen">
    <el-container class="fullscreen">
      <!-- 侧边栏 -->
      <el-aside :width="$store.state.navOpen?'200px' : '' ">
        <left-side></left-side>
      </el-aside>
      <el-container class="flex1 flex">
        <!-- 头部 -->
        <el-header>
          <header-side></header-side>
        </el-header>
        <!-- 右边内容 -->
        <el-main class="flex1">
          <router-view></router-view>
        </el-main>
        <!-- <el-footer>Footer</el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import leftSide from "@/components/common/leftSide.vue";
import headerSide from "@/components/common/headerSide.vue";
export default {
  components: { leftSide, headerSide },
  props: {},
  data() {
    return {};
  },

  created() {},
  mounted() {},
  computed: {},

  methods: {},
};
</script>
<style lang='scss' scoped>
::v-deep .el-header {
  height: 40px !important;
  padding: 0px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
</style>