<template>
  <div class="home fullscreen">
    <router-view></router-view>
  </div>
</template>

<script>
import leftSide from "@/components/common/leftSide.vue";
import headerSide from "@/components/common/headerSide.vue";
export default {
  components: { leftSide, headerSide },
  props: {},
  data() {
    return {};
  },

  created() {},
  mounted() {},
  computed: {},

  methods: {},
};
</script>
<style lang='scss' scoped>
::v-deep .el-header {
  height: 40px !important;
  padding: 0px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
</style>