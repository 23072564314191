<template>
  <!-- <div class="left_side fullscreen"> -->
  <!-- <div v-if="$store.state.isPhone && $store.state.navOpen" @click="$store.state.navOpen = !$store.state.navOpen" class="mask"></div> -->
  <el-menu :unique-opened="true" router background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" :default-active="$route.path" class="fullscreen" :collapse="!$store.state.navOpen">
    <el-submenu :index="item.path" v-for="(item,i) in sidebarItems" :key="i">
      <template slot="title">
        <i :class="item.icon"></i>
        <span slot="title">{{ item.meta.title }}{{ defaultSub }}</span>
      </template>
      <el-menu-item-group>
        <el-menu-item :index="child.path" v-for="(child,c) in item.children">{{ child.meta.title }}</el-menu-item>
      </el-menu-item-group>

    </el-submenu>
    <!-- <el-submenu index="/person">
      <template slot="title">
        <i class="el-icon-goods"></i>
        <span slot="title">人员管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/person/personList">用户列表</el-menu-item>
      </el-menu-item-group>
      <el-menu-item-group>
        <el-menu-item index="/person/roleList">角色列表</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-submenu index="/shop">
      <template slot="title">
        <i class="el-icon-goods"></i>
        <span slot="title">店铺管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="/shop/commodity">商品列表</el-menu-item>
      </el-menu-item-group>

    </el-submenu> -->
  </el-menu>

  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {},
  data() {
    return {
      defaultSub: "",
      isCollapse: false,
    };
  },

  created() {},
  mounted() {},
  computed: {
    ...mapGetters(["sidebarItems"]),
  },

  methods: {
    getWidth() {
      var widthStr;
      if (this.$store.state.isPhone) {
        widthStr = this.$store.state.navOpen ? "width: 200px;" : "width:0px;";
      } else {
        widthStr = this.$store.state.navOpen ? "width: 200px;" : "width:64px;";
      }
      return widthStr;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style lang='scss' scoped>
.left_side {
  background: #545c65 !important;
}
/deep/ .el-menu {
  border-right: 0;
}
/deep/.el-menu-item-group__title {
  padding: 0;
}
</style>